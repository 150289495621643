import React, { useState } from 'react';
import './index.css';
import { Link, useNavigate } from 'react-router-dom';

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const emailOnchange = (e) => {
    document.getElementById('tipsText').style.display="none";
    setEmail(e.target.value);
  }

  const passwordOnchange = (e) => {
    document.getElementById('tipsPwd').style.display="none";
    setPassword(e.target.value);
  }

  const login = () => {
      let username = email;
      if (username == "" || password == "") {
        if (username == "" && password == "") {
          document.getElementById('tipsText').style.display="block";
          document.getElementById('tipsPwd').style.display="block";
        }else if(username == ""){
          document.getElementById('tipsText').style.display="block";
        }else{
          document.getElementById('tipsPwd').style.display="block";
        }
      } else {
        document.getElementById('tipsText').style.display="none";
        document.getElementById('tipsPwd').style.display="none";
        let  ls_users = localStorage.getItem('users');
        
        if(ls_users){
            ls_users = JSON.parse(ls_users)
            if(ls_users.username !== "" && ls_users.password !== "") {
              if(ls_users.username === username && ls_users.password === password){
                navigate('/', { replace: true });
              }else{
                alert('用户名或登录密码输入错误');
              }
            }else {
              localStorage.setItem('users',JSON.stringify({username,password}))
            }
        } else {
            //没有用户注册，直接保存到本地存储
            localStorage.setItem('users',JSON.stringify({username,password}))
        }
    }
  }

  return(
    <div className='loginBg'> 
      <div className='loginTitle'>登录</div>
      <div className='inputView'>
        <div className='inputTitle' type='text' >电子邮件</div>
        <input className='inputBox' onChange={emailOnchange} />
      </div>
      <p className='tipsLabel' id='tipsText'>电子邮件不能为空</p>
      <div className='inputView'>
        <div className='inputPassword' type='password'  >密码</div>
        <input className='inputBox' type='password'  onChange={passwordOnchange}/>
      </div>
      <p className='tipsLabel' id='tipsPwd'>密码不能为空</p>
      <div className='checkBoxView'>
        <input type='checkbox' />
        <div className='checkboxLabel'>记住我？</div>
      </div>
      <button className='loginBtn' onClick={login}>登录</button>
      <Link className='registerLabel' to="/register">注册为新用户</Link>
    </div>
  )
};

export default LoginPage;