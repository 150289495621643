import React, { useState } from 'react';
import './index.css';
import {  useNavigate } from 'react-router-dom';

function RegisterPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confPwd, setConfPwd] = useState('');
  const [name, setName] = useState('');
  const [idCard, setIdCard] = useState('');
  const [checked, setChecked] = useState(false);

  const onCheckClick = (e) => {
    if(e.target.checked == true){
      document.getElementById('checkBox').style.display="none";
    }
    setChecked(e.target.checked);
  }

  const onEmailChange = (e) => {
    document.getElementById('tipsText').style.display="none";
    setEmail(e.target.value);
  }

  const onPwdChange = (e) => {
    document.getElementById('tipsPwd').style.display="none";
    setPassword(e.target.value);
  }

  const onConfPwdChange = (e) => {
    document.getElementById('tipsConfpwd').style.display="none";
    setConfPwd(e.target.value);
  }

  const onNameChange = (e) => {
    document.getElementById('tipsName').style.display="none";
    setName(e.target.value);
  }

  const onIDCardChange = (e) => {
    document.getElementById('tipsIDCard').style.display="none";
    setIdCard(e.target.value);
  }

  const onRegisterClick = () => {
    let isEmpty = email == '' || password == "" || confPwd == "" || name == "" || idCard == "";
    if( isEmpty ){
      if(email == "") {
        document.getElementById('tipsText').style.display="block";
      }if(password == "") {
        document.getElementById('tipsPwd').style.display="block";
      }if(confPwd == "") {
        document.getElementById('tipsConfpwd').style.display="block";
      } if(name == "") {
        document.getElementById('tipsName').style.display="block";
      } if(idCard == "") {
        document.getElementById('tipsIDCard').style.display="block";
      }
    }else if(checked == false) {
      document.getElementById('checkBox').style.display = "block";
    }else {
      navigate('/login', { replace: true });
    }
    

  }

  const onShowModal = () => {
    document.getElementById('mask').style.display="block";
    document.getElementById('modal').style.display="block";
  }

  const onCloseModal = () => {
    document.getElementById('mask').style.display="none";
    document.getElementById('modal').style.display="none";
  }

  return (
    <div className='registerBg'> 
      <div className='registerTittle'>注册</div>
      <div className='registerInputView'>
        <div className='registerInputTitle'>电子邮件</div>
        <input className='registerInput' onChange={onEmailChange} />
      </div>
      <p className='tipsLabel' id='tipsText'>电子邮件不能为空</p>
      <div className='registerInputView'>
        <div className='registerInputTitle'>密码</div>
        <input className='registerInput' type="password" onChange={onPwdChange}/>
      </div>
      <p className='tipsLabel' id='tipsPwd'>密码不能为空</p>
      <div className='registerInputView'>
        <div className='registerInputTitle'>确认密码</div>
        <input className='registerInput' type="password" onChange={onConfPwdChange}/>
      </div>
      <p className='tipsLabel' id='tipsConfpwd'>密码不能为空</p>
      <div className='registerInputView'>
        <div className='registerInputTitle'>真实姓名</div>
        <input className='registerInput' onChange={onNameChange}/>
      </div>
      <p className='tipsLabel' id='tipsName'>姓名不能为空</p>
      <div className='registerInputView'>
        <div className='registerInputTitle'>身份证号</div>
        <input className='registerInput' onChange={onIDCardChange}/>
      </div>
      <p className='tipsLabel' id='tipsIDCard'>身份证号不能为空</p>
      <div className='checkBoxView'>
        {/* <input type='checkbox' onClick={onCheckClick}/>
        <div className='checkboxLabel'>我已经看过并同意 请点击查看《用户注册服务协议》</div> */}
        <label  className='checkBoxView'>
          <input type='checkbox' onChange={onCheckClick} />
          <div className='userAgreement' id='showlow' onClick={onShowModal}>《用户注册服务协议》</div>我已经看过并同意 请点击查看
        </label>
      </div>
      <p className='tipsLabel' id='checkBox' >请阅读并勾选用户协议</p>
      <button className='registerBtn' onClick={onRegisterClick}>注册</button>
      <div className='mask' id='mask' onClick={onCloseModal} />
      <div className='modal' id='modal'>
          <div className='modal-body'> 
              <p>注意！请您仔细阅读如下全部内容。</p>
              <p>只要您点击"接受"、"同意"或实际使用北京彩小二科技有限公司服务，即表明您同意受本法律文件的约束</p>
              <p>（注：协议中如有不理解地方请参阅官网客服页面或与客服联系）</p>
              <p>
                本协议系北京彩小二科技有限公司服务,在向用户通过域名为funsporx.com等网站提供相关产品和服务（以下简称为“产品和服务”）时与用户达成的关于使用北京彩小二科技有限公司产品和服务的各项规则、条款和条件，以下或称为“本用户协议”。
              </p>
              <p><br/></p>
              <p>1.概述</p>
              <p>1.1定义：</p>
              <p>1.1.1“关联企业”指现在或将来与北京彩小二科技有限公司构成“一方控制、共同控制另一方或对另一方施加重大影响，以及两方或两方以上同受一方控制、共同控制或重大影响”的关系的企业。</p>
              <p>1.1.2“用户”指愿意接受或实际上已经接受北京彩小二科技有限公司提供的产品和服务的个人，以下或简称为“您”。</p>
              <p>1.1.3“通行证”指用户为使用北京彩小二科技有限公司产品和服务需要注册及使用的相应账号及账号信息。</p>
              <p>1.2本协议是用户接受北京彩小二科技有限公司产品和服务时适用的通用条款。因此，请您在注册成为北京彩小二科技有限公司用户前或接受北京彩小二科技有限公司的产品和服务之前，详细地阅读本用户协议的所有内容。&nbsp;
              </p>
              <p>
                1.3在您注册成为北京彩小二科技有限公司用户以接受北京彩小二科技有限公司的服务，或您以其他北京彩小二科技有限公司允许的方式实际使用北京彩小二科技有限公司的产品和服务前，您已充分阅读、理解并接受本协议的全部内容以及北京彩小二科技有限公司公布的各项服务规则（包括但不限于网络游戏防沉迷系统及实名认证服务协议，北京彩小二科技有限公司在单项产品和服务中所发布的公告、规则等），特别是其中与您的权益有或可能有重大关系的条款，及对北京彩小二科技有限公司具有或可能具有免责或限制责任的条款。一旦您使用北京彩小二科技有限公司产品或服务，即表示您同意遵循本协议的所有约定以及北京彩小二科技有限公司公布的相关服务规则等，并愿意受其约束。如果发生纠纷，您不得以未仔细阅读为由进行抗辩。
              </p>
              <p>
                1.4随着经营情况的变化，北京彩小二科技有限公司有权随时更改本用户协议及北京彩小二科技有限公司各产品和服务的相关服务规则。修改本用户协议及各服务规则时，北京彩小二科技有限公司将于北京彩小二科技有限公司网站相关页面或各单项产品和服务的页面公告修改的事实，并有权不对用户进行个别另行通知。用户应该在每次登录或使用北京彩小二科技有限公司产品或服务前查询北京彩小二科技有限公司网站、北京彩小二科技有限公司所提供的产品或服务上的相关公告，以了解本用户协议及服务规则的变化。若用户不同意本用户协议或相关服务规则，或者不同意北京彩小二科技有限公司作出的修改，用户可以主动停止使用北京彩小二科技有限公司提供的产品和服务，如果在北京彩小二科技有限公司修改协议或服务规则后，用户仍继续使用北京彩小二科技有限公司提供的产品和服务，即表示用户同意北京彩小二科技有限公司对本用户协议及相关服务规则所做的所有修改。
              </p>
              <p><br/></p>
              <p>2.账号和密码&nbsp;</p>
              <p>
                2.1用户承诺以其真实身份注册通行证，并保证所提供的个人身份资料信息真实、完整、有效，依据法律规定和本协议约定对所提供的信息承担相应的法律责任。北京彩小二科技有限公司有权审查用户注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；用户有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给对方或他人的民事权利造成损害的，应当承担由此产生的法律责任。
              </p>
              <p>
                2.2用户申请注册成功后，北京彩小二科技有限公司有权根据北京彩小二科技有限公司各产品商业运营情况分配给用户通行证账号。账号一旦设定就不可再为变更。每注册一个通行证账号，即一次合同缔约行为，成就一份独立的服务合同。但北京彩小二科技有限公司有权就同一用户名下的不同账号进行单独或整体的管理。
              </p>
              <p>
                2.3用户在注册时应提供完整、详尽、真实的个人资料，若所提供的资料日后有变更，用户应及时登录账号进行更新及修改。用户以其真实身份注册后，需要修改所提供的个人身份资料信息或账号密码的，北京彩小二科技有限公司应当按照本用户协议及北京彩小二科技有限公司相关服务规则及时、有效地为用户提供该项服务。若用户在北京彩小二科技有限公司留下的个人资料与事实不符，北京彩小二科技有限公司有权停止向其继续提供产品和服务。当用户向北京彩小二科技有限公司主张其拥有某账号时，若该账号在北京彩小二科技有限公司的身份信息记录与用户的身份信息有任何不符，北京彩小二科技有限公司有权不予认定该账号为该用户所有。
              </p>
              <p>
                2.4在没有经过北京彩小二科技有限公司允许并登记的情况下，用户不得将账号及密码泄漏或提供给他人知悉，也不得将账号出借或转让给他人使用。如因用户自身过错或用户计算机感染病毒或木马，而导致账号或密码泄漏的，用户应自行承担由此造成的损失。北京彩小二科技有限公司（包括但不限于北京彩小二科技有限公司的网站和论坛管理人员、客服人员等）不会以任何方式询问用户的密码，所以用户不应对任何人泄漏用户的密码，不要多人共享同一个账号，不要安装非法或来路不明的程序。如果用户遗失了密码，北京彩小二科技有限公司针对处理此问题之服务保留索取额外费用的权利。
              </p>
              <p>
                2.5若用户发现账号或密码被他人非法使用或有异常使用的情形，应立即根据北京彩小二科技有限公司公布的处理方式通知北京彩小二科技有限公司，并有权通知北京彩小二科技有限公司暂停该账号的登录和使用，但用户在申请时应履行必要的手续，诸如提供与其注册身份信息相一致的个人有效身份信息。北京彩小二科技有限公司核实用户所提供的个人有效身份信息与所注册的身份信息相一致的，应当按照本用户协议及北京彩小二科技有限公司的相关服务规则等及时采取措施暂停用户账号的登录和使用。北京彩小二科技有限公司违反上述约定，未及时采取措施暂停用户账号的登录和使用，因此而给用户造成损失的，应当承担其相应的法律责任，但北京彩小二科技有限公司对采取行动之前用户账号已执行的指令不承担责任。用户没有提供其个人有效身份证件或者用户提供的个人有效身份证件与所注册的身份信息不一致的，北京彩小二科技有限公司有权拒绝用户上述请求。&nbsp;
              </p>
              <p>
                2.6北京彩小二科技有限公司会向用户提供一定的免费或收费的保护账号密码的辅助工具，但用户知悉并同意：这些工具只能在一定程度上提高账号和密码的安全，北京彩小二科技有限公司并不担保用户在使用此类辅助工具后就能保障账号密码的绝对安全。
              </p>
              <p><br/></p>
              <p>3.用户的权利&nbsp;</p>
              <p>
                3.1用户可以根据本用户协议以及北京彩小二科技有限公司公布的服务规则等来接受北京彩小二科技有限公司提供的产品和服务。用户有权在接受北京彩小二科技有限公司提供的产品和服务的期间监督北京彩小二科技有限公司及北京彩小二科技有限公司的工作人员是否按照北京彩小二科技有限公司所公布的标准向用户提供产品和服务，也可以随时向北京彩小二科技有限公司提出与北京彩小二科技有限公司的产品和服务有关意见和建议。
              </p>
              <p>3.2用户在拥有合法的账号下，可以在北京彩小二科技有限公司的服务器上传使用服务所必需的信息，但信息中不得含有如下内容：</p>
              <p>3.2.1侵犯任何第三者的知识产权或其他权益的内容；</p>
              <p>3.2.2违反任何法律或善良风俗的内容；</p>
              <p>3.2.3包含任何毁谤他人，性骚扰，种族歧视，或对未成年人有不良影响的内容；</p>
              <p>3.2.4包含病毒、木马程序、定时炸弹等可能对北京彩小二科技有限公司或任何人的计算机系统造成伤害或影响其稳定性的内容。</p>
              <p>北京彩小二科技有限公司有权依照上传内容的严重性采取任何动作，包括但不限于根据第三方提出的权利主张屏蔽或删除用户上传的信息。</p>
              <p>3.3用户特此授权北京彩小二科技有限公司免费使用用户上传的内容。&nbsp;</p>
              <p>
                3.4如果用户不同意本用户协议或相关服务规则，不接受北京彩小二科技有限公司对本用户协议或服务规则不时做出的修改，用户可以随时停止使用北京彩小二科技有限公司的产品和服务。如果用户选择停止使用北京彩小二科技有限公司的产品和服务，则视为用户放弃其通行证账号使用权，北京彩小二科技有限公司对用户不承担任何义务和责任。
              </p>
              <p><br/></p>
              <p>4.用户的义务&nbsp;</p>
              <p>4.1用户需自行配备注册和使用北京彩小二科技有限公司产品和服务所需的各项计算机及网络设备以及网络资源，并自行负担上网所需的各项费用。</p>
              <p>
                4.2用户有义务妥善保管使用北京彩小二科技有限公司通行证和北京彩小二科技有限公司各产品及服务的账号、密码及其他与北京彩小二科技有限公司通行证和北京彩小二科技有限公司各产品及服务有关的身份信息和证件。用户对登录后所持账号产生的行为依法享有权利和承担责任，使用该账号的人即被视为用户本人，其做出的任何行为也将视为用户的行为，用户应当为此账号使用者的一切行为负责。
              </p>
              <p>
                北京彩小二科技有限公司有权根据服务的重要性不同，制定不同的安全级别策略，并要求用户提供相应级别的身份验证信息及资料，包括但不限于：账号、密码、超级身份验证码、身份证件复印件、原件、户籍证明等。当某项服务的申请者按北京彩小二科技有限公司既定的方式通过身份验证后，北京彩小二科技有限公司即可视其为该账号用户并依其申请提供服务，由此产生的一切后果由拥有该账号的用户自行承担。
              </p>
              <p>4.3用户同意遵守万利购网站和客服中心(FAQ)各项条款，用户应每周查看北京彩小二科技有限公司公布的各项条款。&nbsp;</p>
              <p>4.4用户在使用北京彩小二科技有限公司产品和接受北京彩小二科技有限公司服务的过程中，用户：</p>
              <p>4.4.1不得为任何非法目的而使用服务系统；</p>
              <p>4.4.2不得采取任何可能影响北京彩小二科技有限公司的网络服务的非正常使用行为（包括但不限于损害、攻击服务器或使服务器过度负荷等）；</p>
              <p>4.4.3不得使用任何外挂程序或游戏修改程序；</p>
              <p>4.4.3不得通过第三方软件或在第三方软件的辅助下使用北京彩小二科技有限公司的产品或接受北京彩小二科技有限公司的服务；</p>
              <p>4.4.4不得采取任何破坏游戏规则和违反游戏公平性的行为；为避免破坏游戏的公平性或平衡性，用户同意并理解其只能通过的产品和服务进行正常的娱乐互动。&nbsp;</p>
              <p>4.4.5不得使用任何方式或方法，试图攻击提供游戏服务的相关服务器、路由器、交换机以及其他设备，以达到非法获得或修改未经授权的数据资料、影响正常游戏服务，以及其他危害性目的的任何行为；</p>
              <p>4.4.6不得利用线上游戏系统可能存在的技术缺陷或漏洞而以各种形式为自己及他人牟利或者从事其他不正当行为。</p>
              <p>4.4.7不得利用北京彩小二科技有限公司产品和网络服务系统进行任何可能对互联网的正常运转造成不利影响或可能干扰他人以正常方式使用北京彩小二科技有限公司所提供的产品和网络服务的行为；</p>
              <p>4.4.8不得利用北京彩小二科技有限公司网络服务系统传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的、欺诈性的或其他任何非法的信息资料；</p>
              <p>4.4.9不得利用北京彩小二科技有限公司服务系统进行其他不利于北京彩小二科技有限公司的行为；</p>
              <p>4.4.10不得使用从非由北京彩小二科技有限公司指定的渠道所获得的资料或信息；</p>
              <p>4.5如发现任何非法使用用户账号或账号出现安全漏洞的情况，应立即通知北京彩小二科技有限公司；</p>
              <p>4.6当用户账户涉嫌盗号等非法和违规行为时，用户应配合北京彩小二科技有限公司的工作，据实回答游戏管理人员提出的与使用北京彩小二科技有限公司产品相关的问题，以维护自己和其他用户在游戏中的合法权益；</p>
              <p><br/></p>
              <p>5.取消账号和终止、中止（即有期限停止）服务</p>
              <p>5.1用户有如下任何一种或多种行为的，北京彩小二科技有限公司有权随时取消该用户账号或终止或中止对该用户的全部或部分服务（包括但不限于封停该用户名下全部或部分账号和禁止有关角色登录）</p>
              <p>
                5.2北京彩小二科技有限公司通过内部的监测程序发现或经其他用户举报发现用户有可能从事第5.1条所列行为，则北京彩小二科技有限公司有权作出独立的判断并采取相应的措施，该措施包括但不限于限制用户账号的登陆、限制用户在游戏中的活动，中止、终止对该用户名下部分或所有账号的服务，用户不用不得以此向北京彩小二科技有限公司主张任何权利。
              </p>
              <p>5.3同一用户注册的账号中有一个账号或多个账号出现违反本协议约定的行为，北京彩小二科技有限公司有权根据实际情况，中止、终止对该用户名下部分或所有账号的服务，或取消该用户名下部分或所有账号。</p>
              <p>
                5.5若一个北京彩小二科技有限公司通行证账号连续360天未登录北京彩小二科技有限公司任意一款网络游戏的，视为用户放弃该账号，北京彩小二科技有限公司服务系统可以不再保留该账号及与该账号有关的数据，用户不得以此向北京彩小二科技有限公司主张任何权利。
              </p>
              <p><br/></p>
              <p>6.网络服务中断&nbsp;</p>
              <p>发生下列情形之一时，即使未经通知，北京彩小二科技有限公司有权中断所提供的网络服务，并不需要向用户承担任何责任：</p>
              <p>6.1定期对有关的网站服务器或游戏服务器及其他网络设备或所提供的产品和网络服务的相关官方网站进行必要的维护、保养及施工；用户可以到北京彩小二科技有限公司官方网站和游戏公告中查询每日停机维护时间；</p>
              <p>6.2根据北京彩小二科技有限公司的判断，随时对有关的网站服务器或游戏服务器及其他网络设备或所提供的产品和网络服务的相关官方网站进行必要的维护、保养及施工；</p>
              <p>6.3因北京彩小二科技有限公司、北京彩小二科技有限公司的合作方或电信网络系统软硬件设备的故障、失灵或人为操作的疏失；</p>
              <p>6.4他人侵入北京彩小二科技有限公司的网络，篡改、删改或伪造、编造网站数据，或实施了任何影响北京彩小二科技有限公司计算机系统正常运行的行为；</p>
              <p>6.5不可抗力原因；</p>
              <p>6.6由于相关机构基于法律或法定程序的要求；</p>
              <p>6.7其他基于法律或国家政策的规定。</p>
              <p><br/></p>
              <p>7.终止网络服务&nbsp;</p>
              <p>7.1用户不得因北京彩小二科技有限公司全面终止网络服务而要求北京彩小二科技有限公司承担除用户任何形式的赔偿或补偿责任，包括但不限于因不再能继续使用游戏账号而要求的赔偿。</p>
              <p>7.2终止网络服务指北京彩小二科技有限公司向停止网络服务的游戏产品的全部的用户终止网络服务而非对个别用户进行的封号、禁止登陆、强制离线等措施。</p>
              <p><br/></p>
              <p>8.隐私保护</p>
              <p>
                保护用户的隐私是北京彩小二科技有限公司的一项基本政策。本公司重视对您的隐私的保护，一直并将继续努力采取各种合理的物理、电子和管理方面的安全措施来保护用户信息。适用于用户的隐私管理规则以北京彩小二科技有限公司公布的《北京彩小二科技有限公司隐私政策》为准，请您详细阅读该政策的内容。
              </p>
              <p><br/></p>
              <p>9.违约责任&nbsp;</p>
              <p>9.1用户同意保障和维护北京彩小二科技有限公司及其他用户的利益，如因用户违反有关法律、法规或本用户协议项下的任何条款而给北京彩小二科技有限公司或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。&nbsp;
              </p>
              <p>9.2用户同意补偿北京彩小二科技有限公司一切相关人员，因用户违反相关法律法规或本用户协议所产生之一切损害及责任。</p>
              <p><br/></p>
              <p>10.法律管辖&nbsp;</p>
              <p>
                10.1本产品和服务相关规范的解释及适用，以及用户因使用北京彩小二科技有限公司产品和服务而与北京彩小二科技有限公司之间所产生的权利义务关系，应适用中华人民共和国法律（不含涉外民事法律适用法或其他类似法规）。如果本用户协议的任何内容与法律相抵触，应以法律规定为准，同时北京彩小二科技有限公司将重新解释或修改相关的条款，而本用户协议的其他部分将保持对用户的法律效力。
              </p>
              <p>10.2与本协议有关的一切争议，包括但不限于因本协议的签订或履行产生的争议、在本协议履行过程中产生的争议以及因本协议下产品和服务所产生的争议，均应以北京彩小二科技有限公司所在地法院为第一审管辖法院。</p>
              <p><br/></p>
              <p>网络游戏防沉迷系统及实名认证服务协议</p>
              <p>1.本协议性质&nbsp;</p>
              <p>1.1本协议按照《关于保护未成年人身心健康实施网络游戏防沉迷系统的通知》(以下简称《防沉迷通知》)而制定，并自2018年_3_月_15_日正式实施起生效执行。</p>
              <p>1.2本协议是网络游戏运营商北京彩小二科技有限公司及其关联企业（以下简称“运营商”）与用户为实施网络游戏防沉迷系统及实名认证工作所订立的权利义务规范。</p>
              <p>1.3本协议条款如与运营商现行用户账号注册相关条款内容有冲突，以本协议为准。</p>
              <p><br/></p>
              <p>2.本协议的目的&nbsp;</p>
              <p>2.1防止未成年人过度沉迷网络游戏，倡导健康游戏习惯，保护未成年人的身心健康。&nbsp;</p>
              <p>2.2帮助家长及法定监护人了解其未成年子女或其他法定监护对象是否使用网络游戏等情况。</p>
              <p><br/></p>
              <p>3. 网络游戏防沉迷系统开发标准</p>
              <p>3.1 未成年人累计在线2小时以内的游戏时间为“健康”游戏时间。</p>
              <p>3.2 未成年人累计在线游戏2小时之后，再持续下去的2小时游戏时间为“疲劳”游戏时间，此时间段内未成年人获得的游戏收益将降为正常值的50%。</p>
              <p>3.3 未成年人累计在线游戏时间超过5小时为“不健康”游戏时间，不健康时间内未成年人的游戏收益降为0。</p>
              <p><br/></p>
              <p>4.注册须知&nbsp;</p>
              <p>4.1 用户需要按运营商提供的注册方式进行注册申请。</p>
              <p>4.2 用户申请注册新账号时必须填写实名身份信息。实名身份信息必须包括：真实姓名和身份证号码。</p>
              <p>4.3
                _2018_年_3_月_05_日，本游戏的网络游戏防沉迷系统正式实施日前已注册账号的用户(以下简称“老用户”)，享有为期3个月(自_2018_年_6_月_15_日正式实施之日起算，以下简称“补充填写期限”)的实名身份信息补充填写权，补充填写期限满后老用户未补充填写实名身份信息，运营商将依照其原有信息进行实名认证。
              </p>
              <p>4.4
                老用户在补充填写期限内提供的实名身份信息仅作为判断是否纳入网络游戏防沉迷系统之用，不替代老用户原有注册信息，不作为判断该账号权益归属的有效依据。与老用户权益归属相关的一切判断均依照原有信息进行，原有信息缺失的情况下，可适当考虑参照补充填写的实名身份信息。
              </p>
              <p>4.5 在补充填写期限内，如出现冒用老用户名义提供实名身份信息的情况，可在运营商或司法机关对账号归属权进行验证判断后，允许老用户通过合法流程更改账号的实名身份信息。</p>
              <p>4.6 新用户首次注册实名身份信息和老用户的首次补充实名身份信息均为免费，用户的实名身份信息一经注册或者补充即不允许随意修改。</p>
              <p>4.7 用户所填实名身份信息内容不全或在运营商无法识别，用户将收到运营商的提示信息。</p>
              <p><br/></p>
              <p>5.运营商认证原则</p>
              <p>5.1 未满18周岁的用户将被判定为未成年人。</p>
              <p>5.2 达到或者超过18周岁的用户将被初步判定为成年人。</p>
              <p>5.3 补充填写期限到期后，未进行补充实名身份信息并且原注册信息不符合实名身份要求的老用户将被判定为未成年人。新用户注册的实名身份信息不符合实名认证要求的该用户将被判定为未成年人。</p>
              <p>5.4 上述情况按照以下原则处理：&nbsp;</p>
              <p>5.4.1被判定为未成年人的用户纳入网络游戏防沉迷系统。</p>
              <p>5.4.2初步判定为成年人的用户，其实名身份信息将由运营商提交公安部门进行验证。</p>
              <p>5.4.3当未成年人用户年龄自然成长达到18周岁时，系统自动初步认定其为成年人，其实名身份信息将由运营商提交公安部门进行验证。</p>
              <p>5.5 对于不适用中华人民共和国身份证信息注册的现役军人、外籍人士、港澳台同胞等用户，可根据其合法身份证明复印件或传真件向运营商提出实名认证申请，认证未通过前纳入网络游戏防沉迷系统。</p>
              <p><br/></p>
              <p>6.安全保障措施。</p>
              <p>6.1 运营商对用户所提供的实名身份信息进行严格的管理及保护，并将使用现有的技术，尽力防止用户的个人资料丢失、被盗用或遭篡改。</p>
              <p>6.2 运营商未经用户授权不得公开、修改、透露用户身份信息资料及其它保存在网络游戏防沉迷系统实名认证方案中的保密性内容，除遇下列情形：</p>
              <p>6.2.1根据有关法律规定要求。</p>
              <p>6.2.2基于司法机关或其它国家主管机关的法定程序的要求。</p>
              <p>6.2.3在紧急情况下为保护用户或第三人的人身安全，防止用户及他人权益之重大危害的情形下。</p>
              <p>6.3运营商因自身原因管理不善，导致用户实名身份信息泄露，给用户造成损失的，应承担相关法律责任。</p>
              <p><br/></p>
              <p>7.验证须知</p>
              <p>7.1 运营商定期将经识别分类后初步判定为成年人的实名身份信息批量提交公安部门进行验证，由公安部门判定该信息是否真实，并根据验证结果最终确定该用户是否属于成年人。</p>
              <p>7.2运营商将建立实名身份信息数据库，逐步积累已经验证的实名身份信息，用于对新用户提供的实名身份信息进行比对。比对一致的将不再重复送交公安部门验证。</p>
              <p>7.3用户实名身份信息经公安部门验证未通过的将被纳入网络游戏防沉迷系统。</p>
              <p>7.4
                运营商无法确保公安部门现有身份信息库的完整，用户对此现状已明确了解。提供本人的身份证复印件、户口薄复印件、联系电话等真实有效的身份证明后可向运营商提出将使用本人身份信息注册的账号归入网络游戏防沉迷系统的要求。如用户需进一步主张自身的权利，根据相关法规和程序处理。
              </p>
              <p>7.5运营商自用户提交实名身份信息后一个自然月内完成验证。</p>
              <p><br/></p>
              <p>8.查询系统须知&nbsp;</p>
              <p>8.1 网络游戏实名认证信息查询系统，供家长及法定监护人对其未成年子女或其他法定监护对象是否使用网络游戏，是否受到网络游戏防沉迷系统的保护等情况进行查询，以及供公民查询本人身份信息有无被他人使用的情况。</p>
              <p>8.2
                查询者在使用查询系统时需通过书面形式提出申请，并附本人的身份证复印件、户口薄复印件、联系电话、邮编、邮寄地址或电子邮件等真实有效的身份证明信息，方可向运营商提出查询本人身份信息有无被他人使用的申请；如需依法查询被监护人是否使用网络游戏，需要另外提供经公证的能够证明本人与被监护人法定监护关系的证明材料。
              </p>
              <p>8.3
                公民如发现本人身份信息被他人使用，在提供本人经公证的身份证复印件、户口薄复印件、联系电话等真实有效的身份证明后可向运营商提出将使用本人身份信息注册的账号归入网络游戏防沉迷系统的要求。如公民需进一步主张本人的权利，应根据相关法律和程序处理。
              </p>
              <p>8.4 对于查询者的查询需求，自收到申请函之日起运营商需在一周内对其中的身份信息公证证明进行形式上的核对后，将处理情况复函通知查询者。</p>
              <p><br /></p>
              <p>9.免责须知&nbsp;</p>
              <p>9.1
                鉴于现有技术及运营商资源的局限性，运营商在此特别提示用户在本网络游戏防沉迷系统及实名认证实施过程中可能存在缺陷及瑕疵，用户对此表示理解和认可同意并自动接受此服务协议的约定内容，运营商在依照本协议所述履行相应义务的前提下，用户承诺不就此追究运营商的责任。
              </p>
              <p>9.2 由于用户向运营商提供错误、不完整、不实信息等致使本人遭受任何损失，责任自负。&nbsp;</p>
              <p>9.3由于用户个人原因将密码告知他人或未保管好自己的密码或与他人共享注册账户或任何其它非运营商的过错，导致本人实名认证信息泄露，运营商不承担责任。</p>
            <div className='closeModal'>
              <button className='closeButton' onClick={onCloseModal}>关闭</button>
            </div>
          </div>
      </div>
    </div>
  );
};

export default RegisterPage;