import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import logoPng from '../../images/logo.png';

function HeaderNav () {
  return (
    <div className="header">
      <div className='container'>
        <div className="logo">
          <img src={logoPng} alt="北京奕趣互彩科技有限公司" />
        </div>
        <div className="menus">
          <div className='item'>
            <Link to="/">咨讯列表</Link>
          </div>
          <div className='item'>
            <Link to="/login">登录</Link>
          </div>
          <div className='item'>
            <Link to="/register">注册</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;