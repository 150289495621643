import React from 'react';
import './index.css';

function Footer () {
  return (
    <div className="footer">
      <div className="compony-info">
        {/* <ul>
          <li>北京市朝阳区酒仙桥路20号19层1908</li>
          <li> +86-010-56750888</li>
          <li> +86-010-56750999</li>
        </ul> */}
        <div>公司地址：北京市朝阳区酒仙桥路20号19层1907</div>
        <div>联系电话：+86-010-56750888; +86-010-56750999</div>
      </div>
      <div className="icp-info">
        版权所有 © 2023 北京彩小二科技有限公司　 <a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备18017630号-1 </a> ，增值电信业务经营许可证B2-20181427 
      </div>
    </div>
  );
};

export default Footer;