import React from 'react';
import { PureComponent } from 'react';
import './index.css';
import modalImg2 from '../../images/modalImg2.png'

class HomeList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }
  onOpenModal() {
    document.getElementById('mask').style.display="block";
    document.getElementById('modalQR').style.display="block";
  }

  onCloseModal() {
    document.getElementById('mask').style.display="none";
    document.getElementById('modalQR').style.display="none";
  }

  render() {
    const { listData } = this.props;
    
    let listItems = null;
    listItems = (
      <div className='listView'>
        <div className='listRow'>
          {
            listData.row1.map((row, index) => {
              return (
                <div className='listItem' key={`homelist_${index}`}>
                  <img src={row.img} alt="" />
                  <div className='itemTitle'>{row.title}</div>
                  <div className='itemBottom'>
                    <div className='itempPrice'>{row.price}</div>
                    <div className='bottomBtn' onClick={this.onOpenModal}>立即查看</div>  
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className='listRow'>
          {
            listData.row2.map((row, index) => {
              return (
                <div className='listItem'  key={`homelist2_${index}`}>
                  <img src={row.img} alt="" />
                  <div className='itemTitle'>{row.title}</div>
                  <div className='itemBottom'>
                    <div className='itempPrice'>{row.price}</div>
                    <div className='bottomBtn'  onClick={this.onOpenModal}>立即查看</div>  
                  </div>
                </div>
              )
            })
          }
        </div>
          
        </div>
    )

    return(
      <div className="qr_container">
        <div className='mask' id='mask' onClick={this.onCloseModal} />
        <div className='modal' id='modalQR'>
            <img src={modalImg2} />
        </div>
        {listItems}
      </div>
   
    )

  }
}

export default HomeList;


