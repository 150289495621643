import React from 'react';
import './index.css';
import HomeList from  '../../components/HomeList';
import bannerImg from '../../images/banner.jpg';
import itemImg1 from '../../images/1.jpg'
import itemImg2 from '../../images/2.jpg'
import itemImg3 from '../../images/3.jpg'
import itemImg4 from '../../images/4.jpg'
import itemImg5 from '../../images/5.jpg'
import itemImg6 from '../../images/6.jpg'

const listData = {
  row1:[
    {
      img: itemImg1,
      title: "近5场全红 德乙2串1，达姆施塔特攻击力无法挡？",
      price: "¥ 58元"
    },
    {
      img:itemImg2,
      title: "挪超美职2串1，纳什维尔刚回胜轨还能继续赢吗？",
      price: "¥ 58元"
    },
    {
      img: itemImg3,
      title: "瑞典超德乙2串1，马格德堡有黑马潜质？",
      price: "¥ 58元"
    }

  ],
  row2:[
    {
      img: itemImg4,
      title: "美职2串1，华盛顿宣布鲁尼挂帅，这场有新气象？",
      price: "¥ 58元"
    },
    {
      img: itemImg5,
      title: "韩职5连红2串1，城南能否延续胜轨？蔚山会不会轻易掉链？",
      price: "¥ 58元"
    },
    {
      img: itemImg6,
      title: "日职单场，强强对话，鹿岛客场终于要转颓？",
      price: "¥ 58元"
    }
  ]

} 

function IndexPage () {
  return (
    <div className="bg">
      <div className="banner">
        <img src={bannerImg} alt=""/>
      </div>
      <div className="listTop">资讯列表（收费）</div>
      <div className="listBg">
        <HomeList  listData={listData}/>
      </div>
      
    </div>
    
  );
};

export default IndexPage;